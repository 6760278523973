export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

// ========================================================
// ========================================================

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";

// ========================================================
// ========================================================

export const CURRENT_USER = "CURRENT_USER";
export const CURRENT_USER_SUCCESS = "CURRENT_USER_SUCCESS";
export const CURRENT_USER_FAILURE = "CURRENT_USER_FAILURE";

// ========================================================
// ========================================================

export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAILURE = "FORGET_PASSWORD_FAILURE";

// ========================================================
// ========================================================

export const API_ERROR = "LOGIN_API_ERROR";

// ========================================================
// ========================================================

export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

// ========================================================
// ========================================================

export const VERIFY_USER = "VERIFY_USER";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
export const VERIFY_USER_FAILURE = "VERIFY_USER_FAILURE";

// ========================================================
// ========================================================

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

// ========================================================
// ========================================================

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

// ========================================================
// ========================================================

export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

// ========================================================
// ========================================================

export const ASSIGN_COMPANY = "ASSIGN_COMPANY";
export const ASSIGN_COMPANY_SUCCESS = "ASSIGN_COMPANY_SUCCESS";
export const ASSIGN_COMPANY_FAILURE = "ASSIGN_COMPANY_FAILURE";

// ========================================================
// ========================================================

export const REMOVE_ASSIGNED_COMPANY = "REMOVE_ASSIGNED_COMPANY";
export const REMOVE_ASSIGNED_COMPANY_SUCCESS =
  "REMOVE_ASSIGNED_COMPANY_SUCCESS";
export const REMOVE_ASSIGNED_COMPANY_FAILURE =
  "REMOVE_ASSIGNED_COMPANY_FAILURE";

// ========================================================
// ========================================================

export const GET_USER_AUDIT_HISTORY = "GET_USER_AUDIT_HISTORY";
export const GET_USER_AUDIT_HISTORY_SUCCESS = "GET_USER_AUDIT_HISTORY_SUCCESS";
export const GET_USER_AUDIT_HISTORY_FAILURE = "GET_USER_AUDIT_HISTORY_FAILURE";

// ========================================================
// ========================================================

export const GET_USER_CONTROL_BOARD = "GET_USER_CONTROL_BOARD";
export const GET_USER_CONTROL_BOARD_SUCCESS = "GET_USER_CONTROL_BOARD_SUCCESS";
export const GET_USER_CONTROL_BOARD_FAILURE = "GET_USER_CONTROL_BOARD_FAILURE";

// ========================================================
// ========================================================

export const GET_ALL_MODULES = "GET_ALL_MODULES";
export const GET_ALL_MODULES_SUCCESS = "GET_ALL_MODULES_SUCCESS";
export const GET_ALL_MODULES_FAILURE = "GET_ALL_MODULES_FAILURE";

// ========================================================
// ========================================================

export const UPDATE_USER_MODULES = "UPDATE_USER_MODULES";
export const UPDATE_USER_MODULES_SUCCESS = "UPDATE_USER_MODULES_SUCCESS";
export const UPDATE_USER_MODULES_FAILURE = "UPDATE_USER_MODULES_FAILURE";

// ========================================================
// ========================================================

export const ADD_USER_MODULE = "ADD_USER_MODULE";
export const ADD_USER_MODULE_SUCCESS = "ADD_USER_MODULE_SUCCESS";
export const ADD_USER_MODULE_FAILURE = "ADD_USER_MODULE_FAILURE";

// ========================================================
// ========================================================

export const GET_USER_MODULES = "GET_USER_MODULES";
export const GET_USER_MODULES_SUCCESS = "GET_USER_MODULES_SUCCESS";
export const GET_USER_MODULES_FAILURE = "GET_USER_MODULES_FAILURE";

// ========================================================
// ========================================================

export const GET_WIN_RATE_PER_QUARTER = "GET_WIN_RATE_PER_QUARTER";
export const GET_WIN_RATE_PER_QUARTER_SUCCESS =
  "GET_WIN_RATE_PER_QUARTER_SUCCESS";
export const GET_WIN_RATE_PER_QUARTER_FAILURE =
  "GET_WIN_RATE_PER_QUARTER_FAILURE";

// ========================================================
// ========================================================

export const ASSIGN_USER = "ASSIGN_USER";
export const ASSIGN_USER_SUCCESS = "ASSIGN_USER_SUCCESS";
export const ASSIGN_USER_FAILURE = "ASSIGN_USER_FAILURE";

// ========================================================
// ========================================================

export const REMOVE_ASSIGNED_USER = "REMOVE_ASSIGNED_USER";
export const REMOVE_ASSIGNED_USER_SUCCESS = "REMOVE_ASSIGNED_USER_SUCCESS";
export const REMOVE_ASSIGNED_USER_FAILURE = "REMOVE_ASSIGNED_USER_FAILURE";

// ========================================================
// ========================================================

export const GET_HEALTH_STATUS = "GET_HEALTH_STATUS";
export const GET_HEALTH_STATUS_SUCCESS = "GET_HEALTH_STATUS_SUCCESS";
export const GET_HEALTH_STATUS_FAILURE = "GET_HEALTH_STATUS_FAILURE";

// ========================================================
// ========================================================

export const GET_USER_COLLABORATORS = "GET_USER_COLLABORATORS";
export const GET_USER_COLLABORATORS_SUCCESS = "GET_USER_COLLABORATORS_SUCCESS";
export const GET_USER_COLLABORATORS_FAILURE = "GET_USER_COLLABORATORS_FAILURE";

// ========================================================
// ========================================================

export const ADD_KEYWORDS = "ADD_KEYWORDS";
export const ADD_KEYWORDS_SUCCESS = "ADD_KEYWORDS_SUCCESS";
export const ADD_KEYWORDS_FAILURE = "ADD_KEYWORDS_FAILURE";

// ========================================================
// ========================================================

export const GET_KEYWORDS = "GET_KEYWORDS";
export const GET_KEYWORDS_SUCCESS = "GET_KEYWORDS_SUCCESS";
export const GET_KEYWORDS_FAILURE = "GET_KEYWORDS_FAILURE";

// ========================================================
// ========================================================
