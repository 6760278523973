import ControlArea from "components/Layout/ControlArea";
import React, { useState, useEffect } from "react";
import Table from "./Table";
import subscribersIcon from "assets/svgs/subscribers.svg";
import { useDispatch, useSelector } from "react-redux";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { getUsers } from "store/actions";
import { useLocation } from "react-router-dom"; // Import useLocation

const Index = () => {
  const dispatch = useDispatch();
  const { users, metadata } = useSelector((state) => state.authentication);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const pageNumberFromQuery = parseInt(queryParams.get("pageNumber")) || 1;
  const pageSizeFromQuery = parseInt(queryParams.get("pageSize")) || 10;
  const SearchValueFromQuery = queryParams.get("SearchValue") || "";
  const companyFromQuery = queryParams.get("CompanyName") || "";

  const [page, setPage] = useState(pageNumberFromQuery);
  const [pageSize, setPageSize] = useState(pageSizeFromQuery);
  const [SearchValue, setSearchValue] = useState(SearchValueFromQuery);
  const [CompanyName, setCompanyName] = useState(companyFromQuery);

  useEffect(() => {
    // Fetch users when page or page size changes
    dispatch(
      getUsers({ pageNumber: page, pageSize, SearchValue, CompanyName })
    );

    // Update URL with pageNumber and pageSize
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set("pageNumber", page);
    newUrl.searchParams.set("pageSize", pageSize);
    newUrl.searchParams.set("SearchValue", SearchValue);
    newUrl.searchParams.set("CompanyName", CompanyName);
    window.history.replaceState({}, "", newUrl);
  }, [dispatch, page, pageSize, SearchValue, CompanyName]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
  };

  return (
    <>
      <div className="users-wrap">
        <ControlArea cardTxt="المستخدمين" icon={subscribersIcon} />
        <Table
          users={users}
          page={page}
          pageSize={pageSize}
          SearchValue={SearchValue}
          setSearchValue={setSearchValue}
        />
        <div className="table--pagination">
          <PaginationControl
            page={page}
            between={2}
            total={metadata?.count}
            limit={metadata?.pageSize}
            changePage={handlePageChange}
            ellipsis={2}
          />
          <select
            className="form-control form-select"
            value={pageSize}
            onChange={handlePageSizeChange}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default Index;
