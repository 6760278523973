import React from "react";
import { useNavigate } from "react-router-dom";

const Filter = ({ filterText, onFilter, onClear, placeholder }) => {
  const navigate = useNavigate();

  const handleClear = () => {
    onClear();
    navigate(""); // Navigate to the root path when clearing the filter
  };

  return (
    <>
      <div className="filter-wrp">
        <div className="form-group">
          <input
            id="search"
            type="text"
            placeholder={placeholder}
            value={filterText}
            onChange={onFilter}
            className="form-control"
          />
          {filterText?.length > 0 && (
            <button className="clear-btn" onClick={handleClear}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#000"
                className="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
              </svg>
            </button>
          )}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#000"
            className="bi bi-search"
            viewBox="0 0 16 16"
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
        </div>
      </div>
    </>
  );
};

export default Filter;
