import React, { useState, useEffect } from "react";
import { Modal, Tab } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import { AddKeyWords } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
const KeywordsModal = ({ show, setShow, selectedUser }) => {
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const dispatch = useDispatch();
  const { allUserkeywords } = useSelector((state) => state.authentication);
  useEffect(() => {
    if (allUserkeywords?.length > 0) {
      setSelectedKeywords((prevKeywords) => {
        const mergedKeywords = [
          ...prevKeywords,
          ...allUserkeywords.filter(
            (keyword) =>
              !prevKeywords.some(
                (prevKeyword) => prevKeyword.value === keyword.value
              )
          ),
        ];
        return mergedKeywords;
      });
    }
  }, [allUserkeywords]);

  console.log(selectedKeywords);

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      centered
      dialogClassName="modal-verify modal-big"
      size="lg"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {selectedUser?.firstName} {selectedUser?.lastName}
          {selectedUser?.etimadCompany && (
            <p>{selectedUser?.etimadCompany?.name}</p>
          )}
        </Modal.Title>
      </Modal.Header>
      <div className="verify-body userDetails">
        <Tab.Container defaultActiveKey={0} transition={true} timeout={1000}>
          <div className="form-wrapper-block">
            <p className="keywords-title">الكلمات الدلالية</p>
            <div>
              <CreatableSelect
                isRtl={true}
                isSearchable={true}
                className=" select-form keywords-select"
                classNamePrefix="select"
                isMulti={true}
                options={
                  selectedKeywords?.map((keyword) => ({
                    value: keyword?.value,
                    label: keyword?.value,
                  })) || []
                }
                onChange={(selected) => {
                  setSelectedKeywords(selected.map((selected) => selected));
                }}
                value={selectedKeywords}
                placeholder="اكتب الكلمات الدلالية ثم اضغط على Enter"
                isClearable={false}
              />
            </div>

            {selectedKeywords?.length > 0 && (
              <div className="selected-regions">
                {selectedKeywords?.map((keyword, index) => (
                  <label key={index}>{keyword?.label}</label>
                )) || []}
              </div>
            )}
          </div>
          <div className="add-btn-wrapper ">
            <button
              className="btn btn-blue"
              onClick={() => {
                dispatch(
                  AddKeyWords({
                    data: {
                      companyId: selectedUser?.companyId,
                      accountId: selectedUser?.id,
                      keyWords: selectedKeywords.map(
                        (keyword) => keyword.value
                      ),
                    },
                    setShow,
                    toast,
                  })
                );
              }}
            >
              إضافة
            </button>
          </div>
        </Tab.Container>
      </div>
    </Modal>
  );
};

export default KeywordsModal;
