import Loader from "components/shared/Loader";
import { getFullDate } from "helpers/functions";
import React, { useState } from "react";
import { Col, Modal, Nav, Row, Tab } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import userIcon from "../../assets/svgs/user.svg";
import WinRateTable from "./WinRateTable";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { getUserAuditHistory } from "store/actions";
const UserDetails = ({ show, setShow, selectedUser }) => {
  const { locale } = useIntl();
  const dispatch = useDispatch();
  const { userAuditHistory, userControlBoard, winRate, loading } = useSelector(
    (state) => state.authentication
  );

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const renderAuditHistory = userAuditHistory?.responseData?.map(
    (item, index) => {
      return (
        <li key={index}>
          <span>
            <div className="icon">
              <img src={userIcon} alt="user" />
            </div>
            {item?.actionName === "Login"
              ? "تم تسجيل الدخول للحساب"
              : item?.actionName === "Logout"
              ? "تسجيل الخروج"
              : null}
          </span>
          {getFullDate(item?.logDate, locale)}
        </li>
      );
    }
  );

  const renderControlBoard = userControlBoard?.map((item, index) => {
    return (
      <Col key={index} lg={4} md={6} xs={12}>
        <div className="control-board-item">
          <div className="inner">
            <h4>
              {item?.type === "offer" &&
                item?.statusType === "Complete" &&
                "العروض المكتملة"}
              {item?.type === "offer" &&
                item?.statusType === "Create" &&
                "العروض المنشأة"}
              {item?.type === "tender" &&
                item?.statusType === "Watched" &&
                "المنافسات المشاهدة"}
              {item?.type === "tender" &&
                item?.statusType === "Favourite" &&
                "المنافسات الملائمة"}
              {item?.type === "tender" &&
                item?.statusType === "Complete" &&
                "المنافسات المكتملة"}
            </h4>
            <ul>
              <li>
                الهدف الشهري: <span>{item?.monthlyGoal}</span>
              </li>
              <li>
                العدد: <span>{item?.count}</span>
              </li>
            </ul>
          </div>
        </div>
      </Col>
    );
  });

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      centered
      dialogClassName="modal-verify modal-big"
      size="lg"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          تفاصيل المستخدم , {selectedUser?.firstName} {selectedUser?.lastName}
          {selectedUser?.etimadCompany && (
            <p>{selectedUser?.etimadCompany?.name}</p>
          )}
        </Modal.Title>
      </Modal.Header>
      <div className="verify-body userDetails">
        <Tab.Container defaultActiveKey={0} transition={true} timeout={1000}>
          <div className="mini-tabs">
            <Nav>
              <Nav.Item>
                <Nav.Link eventKey={0}>سجِــل الدخول</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={1}>بيانات المنافسات</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={2}>نتائج المنافسات</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <Tab.Content>
            <Tab.Pane eventKey={0}>
              {loading ? (
                <Loader />
              ) : (
                <div className="audit-history">
                  {userAuditHistory?.responseData?.length > 0 ? (
                    <>
                      <h4>{userAuditHistory?.totalCount} تسجيل دخول </h4>
                      <ul>{renderAuditHistory}</ul>
                      <div className="table--pagination">
                        <PaginationControl
                          page={page}
                          between={2}
                          total={userAuditHistory?.totalCount}
                          limit={pageSize}
                          changePage={(newPage) => {
                            setPage(newPage);
                            dispatch(
                              getUserAuditHistory({
                                id: selectedUser?.id,
                                PageSize: pageSize,
                                PageNumber: newPage,
                                AuditAction: 1,
                              })
                            );
                          }}
                          ellipsis={2}
                        />
                        <select
                          className="form-control form-select"
                          value={pageSize}
                          onChange={(e) => {
                            setPageSize(Number(e.target.value));
                            dispatch(
                              getUserAuditHistory({
                                id: selectedUser?.id,
                                PageSize: Number(e.target.value),
                                PageNumber: 1,
                                AuditAction: 1,
                              })
                            );
                          }}
                        >
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={30}>30</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </div>
                    </>
                  ) : (
                    <div className="alert alert-warning text-center">
                      لا يوجد سجِــل دخول
                    </div>
                  )}
                </div>
              )}
            </Tab.Pane>
            <Tab.Pane eventKey={1}>
              {loading ? (
                <Loader />
              ) : (
                <div className="control-board">
                  {userControlBoard?.length > 0 ? (
                    <Row>{renderControlBoard}</Row>
                  ) : (
                    <div className="alert alert-warning text-center">
                      لا يوجد بيانات منافسات
                    </div>
                  )}
                </div>
              )}
            </Tab.Pane>
            <Tab.Pane eventKey={2}>
              {loading ? (
                <Loader />
              ) : (
                <div className="winrate">
                  {winRate?.length > 0 ? (
                    <WinRateTable data={winRate} />
                  ) : (
                    <div className="alert alert-warning text-center">
                      لا توجد نتائج منافسات
                    </div>
                  )}
                </div>
              )}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </Modal>
  );
};

export default UserDetails;
