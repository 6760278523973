import {
  takeEvery,
  fork,
  put,
  all,
  call,
  takeLatest,
} from "redux-saga/effects";

// Import Api files
import {
  addKeyWordsApi,
  addUserModuleApi,
  assignCompanyApi,
  assignUserApi,
  deleteUserApi,
  forgetPasswordApi,
  getAllModulesApi,
  getCurrentUser,
  getHealthStatusApi,
  getUserApi,
  getUserAuditHistoryApi,
  getUserCollaboratorsApi,
  getUserControlBoardApi,
  getUserModulesApi,
  getUsersApi,
  getWinRatePerQuarterApi,
  postUserLogin,
  removeAssignedCompanyApi,
  removeAssignedUserApi,
  updateUserApi,
  updateUserModulesApi,
  verifyUserApi,
  getKeyWordsApi,
} from "../../api/users";

// Login Redux States
import {
  ADD_KEYWORDS,
  ADD_USER_MODULE,
  ASSIGN_COMPANY,
  ASSIGN_USER,
  CURRENT_USER,
  DELETE_USER,
  FORGET_PASSWORD,
  GET_ALL_MODULES,
  GET_HEALTH_STATUS,
  GET_USER,
  GET_USER_AUDIT_HISTORY,
  GET_USER_COLLABORATORS,
  GET_USER_CONTROL_BOARD,
  GET_USER_MODULES,
  GET_USERS,
  GET_WIN_RATE_PER_QUARTER,
  LOGIN_USER,
  LOGOUT_USER,
  REMOVE_ASSIGNED_COMPANY,
  REMOVE_ASSIGNED_USER,
  UPDATE_USER,
  UPDATE_USER_MODULES,
  VERIFY_USER,
  GET_KEYWORDS,
} from "./actionTypes";
import {
  AddKeyWordsFailure,
  AddKeyWordsSuccess,
  addUserModuleFailure,
  addUserModuleSuccess,
  apiError,
  assignCompanyFailure,
  assignCompanySuccess,
  assignUserSuccess,
  deleteUserFailure,
  deleteUserSuccess,
  forgetPasswordFailure,
  forgetPasswordSuccess,
  getAllModulesFailure,
  getAllModulesSuccess,
  getCurrentUserFailure,
  getCurrentUserSuccess,
  getHealthStatusFailure,
  getHealthStatusSuccess,
  getUserAuditHistoryFailure,
  getUserAuditHistorySuccess,
  getUserCollaboratorsFailure,
  getUserCollaboratorsSuccess,
  getUserControlBoardFailure,
  getUserControlBoardSuccess,
  getUserFailure,
  getUserModulesFailure,
  getUserModulesSuccess,
  getUserSuccess,
  getUsers,
  getUsersFailure,
  getUsersSuccess,
  getWinRatePerQuarterFailure,
  getWinRatePerQuarterSuccess,
  loginSuccess,
  logoutUserSuccess,
  removeAssignedCompanyFailure,
  removeAssignedCompanySuccess,
  removeAssignedUserFailure,
  removeAssignedUserSuccess,
  updateUserFailure,
  updateUserModulesFailure,
  updateUserModulesSuccess,
  updateUserSuccess,
  verifyUserSuccess,
  getKeyWordsSuccess,
  getKeyWordsFailure,
  getKeyWords,
} from "./actions";

function* loginUser({ payload }) {
  try {
    const { responseData, isSuccess } = yield call(postUserLogin, payload.data);
    yield put(loginSuccess(responseData));
    localStorage.setItem("token", responseData.token);
    if (isSuccess) {
      // reload page
      window.location.reload();
    }
  } catch (error) {
    console.log(error);
    yield put(apiError(error.response?.data?.responseData?.message));
    yield payload?.toast?.error(error.response?.data?.responseData?.message);
  }
}

// ============================================================
// ============================================================

function* logoutUser() {
  try {
    yield put(logoutUserSuccess());
    localStorage.removeItem("token");
  } catch (error) {
    yield put(apiError(error.response?.data?.responseData?.message));
  }
}

// ============================================================
// ============================================================

function* currentUser() {
  try {
    const { responseData } = yield call(getCurrentUser);
    yield put(getCurrentUserSuccess(responseData));
  } catch (error) {
    console.log(error);
    yield put(
      getCurrentUserFailure(error.response?.data?.responseData?.message)
    );
  }
}

// ============================================================
// ============================================================

function* forgetPasswordSaga({ payload }) {
  try {
    const { responseData, message, isSuccess } = yield call(
      forgetPasswordApi,
      payload
    );
    yield put(forgetPasswordSuccess(responseData));
    if (isSuccess) {
      yield payload?.toast?.success(message);
      setTimeout(() => {
        payload?.navigate("/login");
      }, 3000);
    }
  } catch (error) {
    yield put(forgetPasswordFailure(error.response.data));
  }
}

// ============================================================
// ============================================================

function* getUsersSaga({ payload }) {
  try {
    const { responseData } = yield call(getUsersApi, payload);
    yield put(getUsersSuccess(responseData));
  } catch (error) {
    console.log(error);
    yield put(getUsersFailure(error.response.data));
  }
}

// ============================================================
// ============================================================

function* verifyUserSaga({ payload }) {
  try {
    const { responseData, isSuccess } = yield call(verifyUserApi, payload);
    yield put(verifyUserSuccess(payload));
    if (isSuccess) {
      yield payload?.toast?.success(responseData);
      yield payload?.dispatch(
        getUsers({
          pageNumber: payload?.page || 1,
          pageSize: payload?.pageSize || 10,
          SearchValue: payload?.search?.SearchValue || "",
        })
      );
      yield payload?.resetInputs();
    }
  } catch (error) {
    yield put(apiError(error.response.data));
  }
}

// ============================================================
// ============================================================

function* deleteUserSaga({ payload }) {
  try {
    const { responseData, isSuccess, message } = yield call(
      deleteUserApi,
      payload
    );
    yield put(deleteUserSuccess(payload));
    if (isSuccess) {
      yield payload?.toast?.success(responseData);
      yield payload?.dispatch(
        getUsers({
          pageNumber: payload?.page || 1,
          pageSize: payload?.pageSize || 10,
          SearchValue: payload?.search?.SearchValue || "",
        })
      );
    } else {
      yield payload?.toast?.error(message);
    }
  } catch (error) {
    yield put(deleteUserFailure(error.response.data));
  }
}

// ============================================================
// ============================================================

function* updateUserSaga({ payload }) {
  try {
    const { responseData, isSuccess } = yield call(updateUserApi, payload);
    yield put(updateUserSuccess(responseData));
    if (isSuccess) {
      yield payload?.toast?.success("تم تحديث البيانات بنجاح");
      yield payload?.navigate("/users");
    }
  } catch (error) {
    yield put(updateUserFailure(error.response.data));
  }
}

// ============================================================
// ============================================================

function* getUserSaga({ payload }) {
  try {
    const { responseData } = yield call(getUserApi, payload);
    yield put(getUserSuccess(responseData));
  } catch (error) {
    yield put(getUserFailure(error.response.data));
  }
}

// ============================================================
// ============================================================

function* assignCompanySaga({ payload }) {
  try {
    const { responseData, isSuccess, message } = yield call(
      assignCompanyApi,
      payload
    );
    yield put(assignCompanySuccess(responseData));
    if (isSuccess) {
      yield payload?.toast?.success(message || "تم تحديث البيانات بنجاح");
      yield payload?.dispatch(
        getUsers({
          pageNumber: payload?.page || 1,
          pageSize: payload?.pageSize || 10,
          SearchValue: payload?.search?.SearchValue || "",
        })
      );
      yield payload?.resetInputs();
    } else {
      yield payload?.toast?.error(message || "حدث خطأ ما");
    }
  } catch (error) {
    yield put(assignCompanyFailure(error.response.data));
  }
}

// ============================================================
// ============================================================

function* removeAssignedCompanySaga({ payload }) {
  try {
    const { responseData, isSuccess, message } = yield call(
      removeAssignedCompanyApi,
      payload
    );
    yield put(removeAssignedCompanySuccess(responseData));
    if (isSuccess) {
      yield payload?.toast?.success(message || "تم تحديث البيانات بنجاح");
      yield payload?.dispatch(
        getUsers({
          pageNumber: payload?.page || 1,
          pageSize: payload?.pageSize || 10,
          SearchValue: payload?.search?.SearchValue || "",
        })
      );
      yield payload?.resetInputs();
    }
  } catch (error) {
    yield put(removeAssignedCompanyFailure(error.response.data));
  }
}

// ============================================================
// ============================================================

function* getUserAuditHistorySaga({ payload }) {
  try {
    const response = yield call(getUserAuditHistoryApi, payload);
    yield put(getUserAuditHistorySuccess(response));
  } catch (error) {
    yield put(getUserAuditHistoryFailure(error.response.data));
  }
}

// ============================================================
// ============================================================

function* getUserControlBoardSaga({ payload }) {
  try {
    const { responseData } = yield call(getUserControlBoardApi, payload);
    yield put(getUserControlBoardSuccess(responseData));
  } catch (error) {
    yield put(getUserControlBoardFailure(error.response.data));
  }
}

// ============================================================
// ============================================================

function* getAllModulesSaga({ payload }) {
  try {
    const { responseData } = yield call(getAllModulesApi, payload);
    yield put(getAllModulesSuccess(responseData));
  } catch (error) {
    yield put(getAllModulesFailure(error.response.data));
  }
}

// ============================================================
// ============================================================

function* updateUserModuleSaga({ payload }) {
  try {
    const { responseData, isSuccess, message } = yield call(
      updateUserModulesApi,
      payload
    );
    yield put(updateUserModulesSuccess(responseData));
    if (isSuccess) {
      yield payload?.toast?.success(message || "تم تحديث البيانات بنجاح");
      yield payload?.setShow(false);
      yield payload?.dispatch(
        getUsers({
          pageNumber: payload?.page || 1,
          pageSize: payload?.pageSize || 10,
          SearchValue: payload?.search?.SearchValue || "",
        })
      );
    }
  } catch (error) {
    yield put(updateUserModulesFailure(error.response.data));
  }
}

// ============================================================
// ============================================================

function* addUserModuleSaga({ payload }) {
  try {
    const { responseData, isSuccess, message } = yield call(
      addUserModuleApi,
      payload
    );
    yield put(addUserModuleSuccess(responseData));
    if (isSuccess) {
      yield payload?.toast?.success(message || "تم اضافة الصلاحيات بنجاح");
      yield payload?.setShow(false);
      yield payload?.dispatch(
        getUsers({
          pageNumber: payload?.page || 1,
          pageSize: payload?.pageSize || 10,
          SearchValue: payload?.search?.SearchValue || "",
        })
      );
    }
  } catch (error) {
    yield put(addUserModuleFailure(error.response.data));
  }
}

// ============================================================
// ============================================================

function* getUserModuleSaga({ payload }) {
  try {
    const { responseData } = yield call(getUserModulesApi, payload);
    yield put(getUserModulesSuccess(responseData));
  } catch (error) {
    yield put(getUserModulesFailure(error.response.data));
  }
}

// ============================================================
// ============================================================

function* getWinRatePerQuarterSaga({ payload }) {
  try {
    const { responseData } = yield call(getWinRatePerQuarterApi, payload);
    yield put(getWinRatePerQuarterSuccess(responseData));
  } catch (error) {
    yield put(getWinRatePerQuarterFailure(error.response.data));
  }
}

// ============================================================
// ============================================================

function* assignUSerSaga({ payload }) {
  try {
    const { responseData, isSuccess, message } = yield call(
      assignUserApi,
      payload
    );
    yield put(assignUserSuccess(responseData));
    if (isSuccess) {
      yield payload?.toast?.success(message || "تم تحديث البيانات بنجاح");
      yield payload?.dispatch(
        getUsers({
          pageNumber: payload?.page || 1,
          pageSize: payload?.pageSize || 10,
          SearchValue: payload?.search?.SearchValue || "",
        })
      );
      yield payload?.resetInputs();
    }
  } catch (error) {
    yield put(assignCompanyFailure(error.response.data));
  }
}

// ============================================================
// ============================================================

function* removeAssignedUserSaga({ payload }) {
  try {
    const { responseData, isSuccess, message } = yield call(
      removeAssignedUserApi,
      payload
    );
    yield put(removeAssignedUserSuccess(responseData));
    if (isSuccess) {
      yield payload?.toast?.success(message || "تم تحديث البيانات بنجاح");

      yield payload?.dispatch(
        getUsers({
          pageNumber: payload?.page || 1,
          pageSize: payload?.pageSize || 10,
          SearchValue: payload?.search?.SearchValue || "",
        })
      );
      yield payload?.resetInputs();
    }
  } catch (error) {
    yield put(removeAssignedUserFailure(error.response.data));
  }
}

// ============================================================
// ============================================================

function* getHealthStatusSaga({ payload }) {
  try {
    const { responseData } = yield call(getHealthStatusApi, payload);
    yield put(getHealthStatusSuccess(responseData));
  } catch (error) {
    yield put(getHealthStatusFailure(error.response.data));
  }
}

// ============================================================
// ============================================================

function* getUserCollaboratorsSaga({ payload }) {
  try {
    const { responseData } = yield call(getUserCollaboratorsApi, payload);
    yield put(getUserCollaboratorsSuccess(responseData));
  } catch (error) {
    yield put(getUserCollaboratorsFailure(error.response.data));
  }
}

// ============================================================
// ============================================================

function* AddKeyWordsSaga({ payload }) {
  try {
    const { responseData, isSuccess, message } = yield call(
      addKeyWordsApi,
      payload
    );
    yield payload?.setShow(false);
    yield put(AddKeyWordsSuccess(responseData));
    if (isSuccess) {
      // Show success toast
      yield call(
        payload?.toast?.success,
        message || "تم اضافة الكلمات الدلالية بنجاح"
      );
      yield put(
        getKeyWords({
          AccountId: payload?.data?.accountId,
          CompanyId: payload?.data?.companyId,
        })
      );
    }
  } catch (error) {
    yield put(AddKeyWordsFailure(error.response.data));
  }
}
// ============================================================
// ============================================================

function* GetKeyWordsSaga({ payload }) {
  try {
    const { responseData, isSuccess, message } = yield call(
      getKeyWordsApi,
      payload
    );
    if (isSuccess) {
      yield put(getKeyWordsSuccess(responseData));
    }
  } catch (error) {
    yield put(getKeyWordsFailure(error.response.data));
  }
}
// ============================================================
// ============================================================

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export function* watchCurrentUser() {
  yield takeEvery(CURRENT_USER, currentUser);
}

export function* watchForgetPassword() {
  yield takeEvery(FORGET_PASSWORD, forgetPasswordSaga);
}

export function* watchGetUsers() {
  yield takeEvery(GET_USERS, getUsersSaga);
}

export function* watchVerifyUser() {
  yield takeLatest(VERIFY_USER, verifyUserSaga);
}

export function* watchDeleteUser() {
  yield takeLatest(DELETE_USER, deleteUserSaga);
}

export function* watchUpdateUser() {
  yield takeLatest(UPDATE_USER, updateUserSaga);
}

export function* watchGetUser() {
  yield takeLatest(GET_USER, getUserSaga);
}

export function* watchAssignCompany() {
  yield takeLatest(ASSIGN_COMPANY, assignCompanySaga);
}

export function* watchRemoveAssignedCompany() {
  yield takeLatest(REMOVE_ASSIGNED_COMPANY, removeAssignedCompanySaga);
}

export function* watchGetUserAuditHistory() {
  yield takeLatest(GET_USER_AUDIT_HISTORY, getUserAuditHistorySaga);
}

export function* watchGetUserControlBoard() {
  yield takeLatest(GET_USER_CONTROL_BOARD, getUserControlBoardSaga);
}

export function* watchGetAllModules() {
  yield takeLatest(GET_ALL_MODULES, getAllModulesSaga);
}

export function* watchUpdateUserModules() {
  yield takeLatest(UPDATE_USER_MODULES, updateUserModuleSaga);
}

export function* watchAddUserModule() {
  yield takeLatest(ADD_USER_MODULE, addUserModuleSaga);
}

export function* watchGetUserModule() {
  yield takeLatest(GET_USER_MODULES, getUserModuleSaga);
}

export function* watchGetWinRatePerQuarter() {
  yield takeLatest(GET_WIN_RATE_PER_QUARTER, getWinRatePerQuarterSaga);
}

export function* watchAssignUser() {
  yield takeLatest(ASSIGN_USER, assignUSerSaga);
}

export function* watchRemoveAssignedUser() {
  yield takeLatest(REMOVE_ASSIGNED_USER, removeAssignedUserSaga);
}

export function* watchGetHealthStatus() {
  yield takeLatest(GET_HEALTH_STATUS, getHealthStatusSaga);
}

export function* watchGetUserCollaborators() {
  yield takeLatest(GET_USER_COLLABORATORS, getUserCollaboratorsSaga);
}

export function* watchAddKeyWordsSaga() {
  yield takeLatest(ADD_KEYWORDS, AddKeyWordsSaga);
}

export function* watchGetKeyWordsSaga() {
  yield takeLatest(GET_KEYWORDS, GetKeyWordsSaga);
}

// ============================================================
// ============================================================

function* authSaga() {
  yield all([fork(watchUserLogin)]);
  yield all([fork(watchUserLogout)]);
  yield all([fork(watchCurrentUser)]);
  yield all([fork(watchForgetPassword)]);
  yield all([fork(watchGetUsers)]);
  yield all([fork(watchVerifyUser)]);
  yield all([fork(watchDeleteUser)]);
  yield all([fork(watchUpdateUser)]);
  yield all([fork(watchGetUser)]);
  yield all([fork(watchAssignCompany)]);
  yield all([fork(watchRemoveAssignedCompany)]);
  yield all([fork(watchGetUserAuditHistory)]);
  yield all([fork(watchGetUserControlBoard)]);
  yield all([fork(watchGetAllModules)]);
  yield all([fork(watchUpdateUserModules)]);
  yield all([fork(watchAddUserModule)]);
  yield all([fork(watchGetUserModule)]);
  yield all([fork(watchGetWinRatePerQuarter)]);
  yield all([fork(watchAssignUser)]);
  yield all([fork(watchRemoveAssignedUser)]);
  yield all([fork(watchGetHealthStatus)]);
  yield all([fork(watchGetUserCollaborators)]);
  yield all([fork(watchAddKeyWordsSaga)]);
  yield all([fork(watchGetKeyWordsSaga)]);
}

export default authSaga;
