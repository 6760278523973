import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";
import { verifyUser } from "store/actions";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

const VerifyModal = ({ show, setShow, selectedUser, page, pageSize }) => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [freeTrial, setFreeTrial] = useState(false);
  const [isRecommendationKeyWords, setIsRecommendationKeyWords] =
    useState(false);

  const resetInputs = () => {
    setStartDate("");
    setEndDate("");
    setFreeTrial(false);
  };

  // const formatDate = (date) => {
  //   const day = date.getDate();
  //   const month = date.getMonth() + 1; // Months are zero-indexed
  //   const year = date.getFullYear();

  //   return `${year}-${month < 10 ? "0" + month : month}-${day}`;
  // };

  const handleFreeTrialChange = (e) => {
    const isChecked = e.target.checked;
    setFreeTrial(isChecked);

    if (isChecked) {
      const today = new Date();

      const endDateTrial = new Date(today.getTime() + 3 * 24 * 60 * 60 * 1000); // Add 14 days to today
      setStartDate(today);
      setEndDate(endDateTrial);
    } else {
      setStartDate("");
      setEndDate("");
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        resetInputs();
        setShow(false);
      }}
      centered
      dialogClassName="modal-verify"
      size="sm"
    >
      <Modal.Header closeButton>
        <Modal.Title>تفعيل المستخدم </Modal.Title>
      </Modal.Header>
      <div className="verify-body">
        <Row>
          <Col lg={12} md={12}>
            <div className="form-group">
              <label className="form-check">
                <input
                  type="checkbox"
                  checked={freeTrial}
                  onChange={handleFreeTrialChange}
                />
                تجربة مجانية ( 3 ايام )
              </label>
            </div>
          </Col>
          <Col lg={6} md={12}>
            <div className="form-group">
              <label>تاريخ البداية</label>
              <Flatpickr
                value={startDate}
                onChange={(date) => setStartDate(date[0])}
                options={{
                  dateFormat: "Y-m-d",
                  minDate: new Date(),
                }}
                className="form-control"
              />
            </div>
          </Col>
          <Col lg={6} md={12}>
            <div className="form-group">
              <label>تاريخ النهاية</label>
              <Flatpickr
                value={endDate}
                onChange={(date) => setEndDate(date[0])}
                options={{
                  dateFormat: "Y-m-d",
                  minDate: startDate,
                }}
                className="form-control"
              />
            </div>
          </Col>
          <Col lg={12}>
            <p>هل الحساب لديه ميزة منافسات الكلمات الدلالية ؟</p>
            <div className="keywordCheck">
              <div className="form-group">
                <div>
                  <label>
                    <input
                      type="radio"
                      name="status"
                      value={true}
                      className="form-checkbox"
                      onChange={(e) => {
                        setIsRecommendationKeyWords(true);
                      }}
                      checked={isRecommendationKeyWords === true}
                    />

                    <span>
                      <p>نعم</p>
                    </span>
                  </label>
                </div>
              </div>
              <div className="form-group">
                <div>
                  <label>
                    <input
                      type="radio"
                      name="status"
                      value={false}
                      className="form-checkbox"
                      onChange={(e) => {
                        setIsRecommendationKeyWords(false);
                      }}
                      checked={isRecommendationKeyWords === false}
                    />

                    <span>
                      <p>لا</p>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={12}>
            <div className="form-group">
              <button
                className="btn btn-blue"
                onClick={() => {
                  dispatch(
                    verifyUser({
                      data: {
                        userId: selectedUser?.id,
                        fromDateSub: startDate,
                        toDateSub: endDate,
                        freeTrial: freeTrial,
                        companyId : selectedUser?.companyId,
                        isRecommendationKeyWords: isRecommendationKeyWords,
                      },
                      toast,
                      dispatch,
                      page,
                      pageSize,
                      resetInputs,
                    })
                  );
                  setShow(false);
                }}
              >
                تفعيل
              </button>
            </div>
          </Col>
        </Row>
        {/* <div className="form-group">
      <label>تحديد عدد ايام التفعيل</label>
      <input
        type="number"
        className="form-control"
        value={numberOfDays}
        onChange={(e) => setNumberOfDays(e.target.value)}
        min={1}
      />
    </div> */}
      </div>
    </Modal>
  );
};

export default VerifyModal;
