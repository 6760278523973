import React, { useMemo, useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import { Col, Dropdown, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  deleteUser,
  getKeyWords,
  getUserAuditHistory,
  getUserControlBoard,
  getUsers,
  getWinRatePerQuarter,
} from "store/actions";

import Filter from "components/shared/Filter";
import Loader from "components/shared/Loader";
import VerifyModal from "./VerifyModal";
import AssignCompany from "./AssignCompany";

import dotsImg from "../../assets/svgs/dots.svg";
import editIcon from "../../assets/svgs/edit.svg";
import deleteIcon from "../../assets/svgs/delete.svg";
import userconfigIcon from "../../assets/svgs/admins.svg";
import permissionIcon from "../../assets/svgs/person-lock.svg";
import showIcon from "../../assets/svgs/eye.svg";
import collabratorsIcon from "../../assets/svgs/customerIcon.svg";
import sortIcon from "../../assets/svgs/sort.svg";
import keywordsIcon from "../../assets/svgs/keywords.svg";
import AssignUserIcon from "../../assets/svgs/assign-user.svg";
import UserDetails from "./UserDetailsModal";
import UserPlans from "./UserPlans";
import UserCollabartors from "./UserCollabartors";
import AssignUser from "./AssignUser";
import KeywordsModal from "./KeywordsModal";

const Table = ({ users, page, pageSize }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pending, setPending] = useState(true);
  const [show, setShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [showAssignCompany, setShowAssignCompany] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [companyText, setCompanyText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [showUserPermissions, setShowUserPermissions] = useState(false);
  const [showUserCollabartors, setShowUserCollabartors] = useState(false);
  const [showAssignUser, setShowAssignUser] = useState(false);
  const [showUserKeywords, setShowUserKeywords] = useState(false);
  const [selectedDate, setSelectedDate] = useState();

  const [searchParams] = useSearchParams();
  const search = Object.fromEntries([...searchParams]);

  const prevSearchRef = useRef(search);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const prevSearch = prevSearchRef.current;

    const paginationChanged =
      prevSearch.pageIndex !== search.pageIndex ||
      prevSearch.pageSize !== search.pageSize;
    const searchValueChanged =
      prevSearch.SearchValue !== search.SearchValue &&
      search.SearchValue !== "";
    const searchCompanyValueChanged =
      prevSearch.CompanyName !== search.CompanyName &&
      search.CompanyName !== "";

    if (paginationChanged || searchValueChanged || searchCompanyValueChanged) {
      dispatch(
        getUsers({
          pageNumber: page,
          pageSize: pageSize,
          SearchValue: search.SearchValue || "",
          CompanyName: search.CompanyName || "",
          IsDesJoiningDate: selectedDate,
        })
      );
    }

    prevSearchRef.current = search;
  }, [search, dispatch, page, pageSize]);

  const handleFilterTextChange = (e) => {
    const newFilterText = e.target.value;
    setFilterText(newFilterText);

    const queryParams = new URLSearchParams(search);
    queryParams.set("SearchValue", newFilterText);
    navigate(`?${queryParams.toString()}`);
  };

  const handleCompanyTextChange = (e) => {
    const newCompanyText = e.target.value;
    setCompanyText(newCompanyText);

    const queryParams = new URLSearchParams(search);
    queryParams.set("CompanyName", newCompanyText);
    navigate(`?${queryParams.toString()}`);
  };

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      const queryParams = new URLSearchParams(search);
      queryParams.delete("SearchValue");
      queryParams.delete("CompanyName");

      setFilterText("");
      setCompanyText("");
      navigate(`?${queryParams.toString()}`);
    };

    return (
      <div className="header-action-wrapper">
        <Filter
          onFilter={handleFilterTextChange}
          onClear={handleClear}
          filterText={filterText}
          placeholder="بحث عن مستخدم"
        />
        <Filter
          placeholder="بحث عن شركة"
          onFilter={handleCompanyTextChange}
          filterText={companyText}
          onClear={handleClear}
        />
        <div className="actions-wrapper">
          <Dropdown>
            <Dropdown.Toggle>
              <div className="actions-icon">
                <img src={sortIcon} />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <p className="title">ترتيب النتائج حسب :</p>

              <div className="filter-wrapper">
                <p className="filter-title">تاريخ التسجيل</p>

                <div>
                  {[
                    {
                      label: "تاريخ التسجيل الأحدث",
                      value: true,
                    },
                    {
                      label: "تاريخ التسجيل الأقدم",
                      value: false,
                    },
                  ].map((date, index) => (
                    <div className="form-group" key={index}>
                      <div>
                        <label>
                          <input
                            type="radio"
                            name="status"
                            value={date?.value}
                            className="form-checkbox"
                            onChange={(e) => {
                              setSelectedDate(e.target.value);
                              dispatch(
                                getUsers({
                                  pageNumber: page,
                                  pageSize: pageSize,
                                  SearchValue: search.SearchValue || "",
                                  CompanyName: search.CompanyName || "",
                                  IsDesJoiningDate: e.target.value,
                                })
                              );
                            }}
                          />

                          <span>
                            <p>{date?.label}</p>
                          </span>
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, resetPaginationToggle, search, navigate]);

  const handleEdit = (id) => {
    navigate(`/users/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      dispatch(deleteUser({ id, toast, dispatch, search }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleShow = (userRow) => {
    setSelectedUser(userRow);
    setShow(true);
  };

  const handleShowAssignCompany = (userRow) => {
    setSelectedUser(userRow);
    setShowAssignCompany(true);
  };

  const handleShowUserDetails = (userRow) => {
    setSelectedUser(userRow);
    setShowUserDetails(true);
    dispatch(
      getUserAuditHistory({
        id: userRow?.id,
        PageSize: 10,
        PageNumber: 1,
        AuditAction: 1,
      })
    );
    dispatch(
      getUserControlBoard({ id: userRow?.id, companyId: userRow?.companyId })
    );

    dispatch(
      getWinRatePerQuarter({
        useerId: userRow?.id,
      })
    );
  };

  const handleShowUserPermissions = (userRow) => {
    setSelectedUser(userRow);
    setShowUserPermissions(true);
  };

  const handleShowUserCollabartors = (userRow) => {
    setSelectedUser(userRow);
    setShowUserCollabartors(true);
  };

  const handleShowAssignUser = (userRow) => {
    setSelectedUser(userRow);
    setShowAssignUser(true);
  };
  const handleShowUserKeywords = (userRow) => {
    setSelectedUser(userRow);
    dispatch(
      getKeyWords({ AccountId: userRow?.id, CompanyId: userRow?.companyId })
    );
    setShowUserKeywords(true);
  };

  const columns = [
    {
      name: "البريد الإلكتروني",
      selector: (row) => row?.email,
      sortable: true,
    },
    {
      name: "الموبايل",
      selector: (row) => row?.phone,
      sortable: true,
    },
    {
      name: "الشركة المسندة",
      selector: (row) =>
        row?.etimadCompany?.name ? row?.etimadCompany?.name : "--",
      sortable: true,
    },
    {
      name: "مسند الي",
      selector: (row) =>
        row?.parentData?.name ? row?.parentData?.email : "--",
      sortable: true,
    },
    {
      name: "الحالة",
      selector: (row) =>
        row?.isVerified ? (
          "مفعل"
        ) : (
          <button
            className="btn btn-blue"
            onClick={() => {
              handleShow(row);
            }}
          >
            تفعيل
          </button>
        ),
      sortable: true,
    },
    {
      name: "تاريخ التسجيل",
      selector: (row) => {
        const date = new Date(row?.createdOn);
        return date.toISOString().substring(0, 10);
      },
      sortable: true,
    },
    {
      name: "اعدادت",
      cell: (row) => (
        <div className="global-dropdown">
          <Dropdown>
            <Dropdown.Toggle>
              <img src={dotsImg} alt="remove" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleShowUserDetails(row)}>
                <i className="info">
                  <img src={showIcon} alt="edit" />
                </i>
                عرض
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleEdit(row?.id)}>
                <i className="info">
                  <img src={editIcon} alt="edit" />
                </i>
                تعديل
              </Dropdown.Item>

              {row?.isVerified && (
                <>
                  <Dropdown.Item onClick={() => handleShowAssignCompany(row)}>
                    <i className="info">
                      <img src={userconfigIcon} alt="assgignCompany" />
                    </i>
                    اسناد لشركة
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleShowAssignUser(row)}>
                    <i className="info">
                      <img src={AssignUserIcon} alt="assgignUser" />
                    </i>
                    اسناد لمستخدم
                  </Dropdown.Item>
                </>
              )}
              <Dropdown.Item onClick={() => handleShowUserPermissions(row)}>
                <i className="info">
                  <img src={permissionIcon} alt="permission" />
                </i>
                صلاحيات
              </Dropdown.Item>
              {row?.isHasCollaborators && (
                <Dropdown.Item onClick={() => handleShowUserCollabartors(row)}>
                  <i className="info">
                    <img src={collabratorsIcon} alt="collabartors" />
                  </i>
                  المشاركين
                </Dropdown.Item>
              )}

              <Dropdown.Item onClick={() => handleShowUserKeywords(row)}>
                <i className="info">
                  <img src={keywordsIcon} alt="Keywords" />
                </i>
                الكلمات الدلالية
              </Dropdown.Item>

              <Dropdown.Item onClick={() => handleDelete(row?.id)}>
                <i className="delete">
                  <img src={deleteIcon} alt="delete" />
                </i>
                حذف
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      width: "80px",
    },
  ];

  return (
    <>
      <Row>
        <Col lg={12}></Col>
      </Row>
      <div className="table-wrap">
        <div className="card">
          <Col xl={6} md={12} xs={12}>
            <div className="card-head">
              <div>
                <h4>
                  <FormattedMessage id="users" />
                </h4>
                <p>
                  يعرض هذا الجدول <FormattedMessage id="users" />
                </p>
              </div>
            </div>
          </Col>
          <div className="card-body">
            <DataTable
              columns={columns}
              data={users} // Use filtered users
              progressPending={pending}
              progressComponent={<Loader />}
              defaultSortField="name"
              subHeader
              subHeaderComponent={subHeaderComponent}
            />
          </div>
        </div>
      </div>
      {show && (
        <VerifyModal
          show={show}
          setShow={setShow}
          selectedUser={selectedUser}
          search={search}
          page={page}
          pageSize={pageSize}
        />
      )}

      {showAssignCompany && (
        <AssignCompany
          show={showAssignCompany}
          setShow={setShowAssignCompany}
          selectedUser={selectedUser}
          search={search}
          page={page}
          pageSize={pageSize}
        />
      )}

      {showUserDetails && (
        <UserDetails
          show={showUserDetails}
          setShow={setShowUserDetails}
          selectedUser={selectedUser}
        />
      )}

      {showUserPermissions && (
        <UserPlans
          show={showUserPermissions}
          setShow={setShowUserPermissions}
          selectedUser={selectedUser}
        />
      )}

      {showUserCollabartors && (
        <UserCollabartors
          show={showUserCollabartors}
          setShow={setShowUserCollabartors}
          selectedUser={selectedUser}
          page={page}
          pageSize={pageSize}
        />
      )}

      {showAssignUser && (
        <AssignUser
          show={showAssignUser}
          setShow={setShowAssignUser}
          selectedUser={selectedUser}
          search={search}
          page={page}
          pageSize={pageSize}
        />
      )}
      {showUserKeywords && (
        <KeywordsModal
          show={showUserKeywords}
          setShow={setShowUserKeywords}
          selectedUser={selectedUser}
          search={search}
          page={page}
          pageSize={pageSize}
        />
      )}
    </>
  );
};

export default Table;
