import React, { useEffect, useState } from "react";

import server from "api/server";
import { useLocation } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import RequestDemoForm from "./RequestDemoForm";

const Index = () => {
  const { pathname } = useLocation();
  const slug = pathname.split("/")[2];

  const [data, setData] = useState({});
  const [lang, setLang] = useState("ar");

  const onStart = async () => {
    const pageData = await server().get(
      `/Cms/GetCmsSectionBySlugName/${slug}?lang=${lang}`
    );
    setData(pageData.data.responseData || {});
  };

  useEffect(() => {
    onStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <>
      <div className="acc-form mini-tabs">
        <Tab.Container defaultActiveKey={0} transition={true} timeout={1000}>
          <Nav className="lang-tabs">
            <Nav.Item>
              <Nav.Link eventKey={0} onClick={() => setLang("ar")}>
                محتوي اللغة العربية
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={1} onClick={() => setLang("en")}>
                محتوي اللغة الانجليزية
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey={0}>
              <RequestDemoForm lang={lang} data={data} slug={slug} />
            </Tab.Pane>
            <Tab.Pane eventKey={1}>
              <RequestDemoForm lang={lang} data={data} slug={slug} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
};

export default Index;
